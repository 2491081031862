import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/home/Home";
import Booking from "./components/pages/booking/Booking";
import Navbar from "./components/utils/Navbar/Navbar";
import Contact from "./components/pages/contact/Contact";
import Footer from "./components/utils/Footer/Footer";
import Explore from "./components/pages/Explore/Explore";
import Logo from "./components/assets/icon.png";
import Login from "./components/pages/Login/Login";
import Pgdetails from "./components/pages/PGdetails/Pgdetails";
import SearchState from "./components/ContextAPI/SearchState";
import Register from "./components/pages/Register/Register";
import Employee from './components/pages/Employee/Employee';
function App() {
  return (
    <div className="App">
    <SearchState>
        <Navbar />
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pgdetails/:id" element={<Pgdetails />} />
          <Route path="/register" element={<Register/>} />
          <Route path="/employee/:uniqueId" element={<Employee />} />
          

          <Route
            path="*"
            element={
              <>
                <div className="d-flex justify-content-center">
                  <img className="img-fluid " src={Logo} alt="Logo" />{" "}
                </div>
                <h1 className="text-center textsec"> Page Not Found</h1>
              </>
            }
          />
        </Routes>
      <Footer />
      </SearchState>
    </div>
  );
}

export default App;
