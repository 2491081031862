import React from 'react';
import Styles from '../page-styles/detailscard.module.css';

const Detailscard = ({ data }) => {
  return (
    <div className="container-fluid my-3 rounded">
      <img src={data.cover_image} className="card-img-top img-fluid rounded m-2" alt="Big mage" style={{
        height: "400px",
        width: "100%",
        objectFit: "cover",
      }} />
      <div className="card-body">
        <div className="row mx-1">
          {data.additional_images.map((image, index) => (
            <div key={index} className="col-2">
              <img src={image.image} className="img-fluid rounded" alt={`SmallImage ${index + 1}`} />
            </div>
          ))}
        </div>
        <h3 className="card-title textsec">{data.product_name}</h3>
        <small className="text-muted ">{data.address}</small>

        <div className="form-group mx-4 my-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input "
              id="termsAndConditions"
              checked
              muted
            />
            <label className="form-check-label" htmlFor="termsAndConditions">
                        we have very big spacious room and our pg is in heart of the city cleanly maintained paying guest with hygiene maintained
                        </label>
                    </div>
                </div>
                <div className="d-flex  mx-2 my-2 ">
                    <button className={`btn Backorg mx-1 my-2 ${Styles.smallScreen}`}>View Phone No</button>
                    <button className={`btn outlineorg mx-1 my-2 ${Styles.smallScreen}`}>Contact Owner</button>
                </div>
            </div>
        </div>
  );
};

export default Detailscard;
