import React, { useEffect, useState , useContext } from "react";
import Slider from "react-slick";
import Card from "../../../utils/Card/CardForcity";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SearchContext from "../../../ContextAPI/SearchContext";


const ListingatElectc = ({ city, cityurl, bg }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidetoshow, setSlidetoshow] = useState(windowWidth < 600 ? 1 : 3);
  const [PgElecCity, setPgElecCity] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

 
  const { setCityUrl } = useContext(
    SearchContext
  );
   const toComponentB = () => {
    setCityUrl(cityurl);
    navigate("/explore");
    
  };
  useEffect(() => {
    const fetchPremiumList = async () => {
      try {
        const url = `https://popularpg.in/products/${cityurl}`;
        const response = await axios.get(url);
        setPgElecCity(response.data);
        setCount(response.data.count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPremiumList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSlidetoshow(windowWidth < 600 ? 1 : 3);
  }, [windowWidth]);

  const settings = {
    infinite: true,
    slidesToShow: slidetoshow,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className={`${bg} mt-4 pt-4 overflow-hidden`}>
      <div className="d-flex justify-content-center m-2">
        <h1>
          <span className="textorg">Listings at</span>
          <span className="textsec"> {city}</span>
        </h1>
      </div>
      <br />
      <div
        className="align-items-center"
        style={{
          width: "80%",
          marginLeft: "10%",
        }}
      >
        <div className="">
          <Slider {...settings}>
            {PgElecCity.results &&
              PgElecCity.results.map((data) => (
                <div key={data.id}>
                  <div className="d-flex justify-content-center">
                    <Card key={data.id} data={data} />
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
      <br />
      {count > 3 && (
        <div className="d-flex justify-content-center my-2">
          <div className="btn Backorg m-2 px-4 py-2" onClick={toComponentB}>
            View All
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingatElectc;
