import React, { useEffect, useContext, useState } from "react";
import Select from "react-select";
import { FaMapMarkerAlt, FaSearch } from "react-icons/fa";
import Styles from "./Searchbar.module.css";
import { BsGenderMale } from "react-icons/bs";
import { MdShare } from "react-icons/md";
import { Link } from "react-router-dom";
import SearchState from "../../ContextAPI/SearchContext";
import { CgDetailsMore } from "react-icons/cg";

const SearchBar = () => {
  const {
    location,
    setGender,
    setLocation,
    setOccupancyType,
    gender,
    occupancytype,
  } = useContext(SearchState);

  // Location options for the Select component
  const locationOptions = [
    { value: "Marathahalli", label: "Marathahalli" },
    { value: "BTM Layout", label: "BTM Layout" },
    { value: "BTM Layout Stage 1", label: "BTM Layout Stage 1" },
    { value: "BTM Layout Stage 2", label: "BTM Layout Stage 2" },
    { value: "Electronic City", label: "Electronic City" },
    { value: "Electronic City Phase 1", label: "Electronic City Phase 1" },
    { value: "Electronic City Phase 2", label: "Electronic City Phase 2" },
    { value: "Manyata Tech Park", label: "Manyata Tech Park" },
    { value: "Whitefield", label: "Whitefield" },
    { value: "Sarjapur Road", label: "Sarjapur Road" },
    { value: "Madiwala", label: "Madiwala" },
    { value: "Koramangala", label: "Koramangala" },
    { value: "HSR Layout", label: "HSR Layout" },
    { value: "Bellandur", label: "Bellandur" },
    { value: "Lalbagh", label: "Lalbagh" },
    { value: "Kr Market", label: "Kr Market" },
    { value: "Jaynagar", label: "Jaynagar" },
    { value: "Banashankari", label: "Banashankari" },
    { value: "JP Nagar", label: "JP Nagar" },
  ];
  const [showMoreFilters, setShowMoreFilters] = useState(true);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setShowMoreFilters(false);
      } else {
        setShowMoreFilters(true);
      }
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  return (
    <div
      className={`container d-flex justify-content-center ${Styles.aligncenter}`}
    >
      <div className="d-flex justify-content-center flex-wrap">
        <div className="">
          <form
            className="bg-white p-3 rounded"
            style={{ border: "0.8rem solid #061E30" }}
          >
            <div className="d-flex justify-content-center flex-wrap flexdircloumn">
              <div className="">
                <div className="input-group mx-2">
                  <span className="input-group-text textorg">
                    <FaMapMarkerAlt />
                  </span>
                  <Select
                    className="location_input_select"
                    isMulti
                    value={locationOptions.filter((option) =>
                      location.includes(option.value)
                    )}
                    options={locationOptions}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setLocation(selectedValues);
                    }}
                    placeholder="Location"
                    styles={{
                      control: (base) => ({
                        ...base,
                        maxHeight: 200,
                        minWidth: 190,
                        overflowX: "auto",
                      }),
                      menuList: (base) => ({
                        ...base,
                        maxHeight: 200,
                        minWidth: 190,
                        overflowX: "auto",
                      }),
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-sm fs-6 fw-lighter textorg smallshow"
                    onClick={() => setShowMoreFilters(!showMoreFilters)}
                  >
                    {showMoreFilters ? (
                      <CgDetailsMore
                        style={{
                          transform: "rotate(180deg)",
                          transition: "all 0.5s ease",
                        }}
                      />
                    ) : (
                      <CgDetailsMore />
                    )}
                  </button>
                </div>
              </div>
              {showMoreFilters && (
                <>
                  <div className="">
                    <div className="input-group mx-2">
                      <span className="input-group-text textorg">
                        <MdShare />
                      </span>
                      <Select
                        className="location_input_select"
                        isMulti
                        value={occupancytype}
                        options={[
                          { value: "single", label: "Single" },
                          { value: "double", label: "Double" },
                          { value: "triple", label: "Triple" },
                        ]}
                        onChange={(selectedOption) =>
                          setOccupancyType(selectedOption)
                        }
                        placeholder="Sharing Type"
                        styles={{
                          control: (base) => ({
                            ...base,
                            minWidth: 190,
                            width: "fit-content",
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="input-group mx-2">
                      <span className="input-group-text textorg">
                        <BsGenderMale />
                      </span>
                      <Select
                        className="location_input_select"
                        value={gender}
                        options={genderOptions}
                        onChange={(selectedOption) => setGender(selectedOption)}
                        placeholder="Select Gender"
                        styles={{
                          control: (base) => ({
                            ...base,
                            minWidth: 190,
                            width: "fit-content",
                          }),
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="">
                <Link to="/explore" className="btn Backorg w-100">
                  <FaSearch /> Search
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
