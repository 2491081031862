const Data =  [
  {
    "name":"Allam Sumith Reddy",
    "Unique-Id":"f5e25c6e-e54c-4320-a31f-9f250870a466",
    "certificate":"https://i.postimg.cc/d3RW55bP/Allam-Sumith-Reddy-Popular-PG-Internship-Certificate.png",
  },
  {
    "name":"Anurag Chittoria",
    "Unique-Id":"016a0198-10ce-4d01-b1b6-069d3f17f7d8",
    "certificate":"https://i.postimg.cc/jjgyn7gF/Anurag-Chittoria-Popular-PG-Internship-Certificate-1.png",
  },
  {
    "name":"EDIGA VENKATESH GOWD",
    "Unique-Id":"cf81862b-a7aa-4a25-b608-6bc3e5d66761",
    "certificate":"https://i.postimg.cc/R0K4MBfc/Ediga-Venkatesh-Gowd-Popular-PG-Internship-Certificate.png",
  },
  {
    "name":"Juhi Singh",
    "Unique-Id":"396541a7-faa5-4c54-8946-065b7d6eed51",
    "certificate":"https://i.postimg.cc/gkHX6hrH/Juhi-Singh-Popular-PG-Internship-Certificate-1.png",
  }, 
  {
    "name":"Brahmadevu Hritesh Kumar",
    "Unique-Id":"8ee0605f-e8c5-4096-b80a-17b083e7dc8d",
    "certificate":"https://i.postimg.cc/RhM3PCDH/Hritesh-Popular-PG-Internship-Certificate.png",
  }, 
  {
    "name":"Monesh R",
    "Unique-Id":"559d0eb5-2ce7-41af-a2cc-21d56851e5a2",
    "certificate":"https://i.postimg.cc/mDs8N298/Monesh-Popular-PG-Internship-Certificate.png",
  }, 
  {
    "name":"Dakaraju Uday Karthik",
    "Unique-Id":"3ea2b29c-b45f-4fc7-aca2-f54115e68dbc",
    "certificate":"https://i.postimg.cc/W4JHwHHb/Karthik-Popular-PG-Internship-Certificate.png",
  }, 
  {
    "name":"Munugoti Sai Ruthvik",
    "Unique-Id":"e2c08491-47d2-457a-9425-a8d5fa955ccc",
    "certificate":"https://i.postimg.cc/Kz89JddY/Sai-Popular-PG-Internship-Certificate-pdf.png",
  }, 
  {
    "name":"Nutalapati Jaideep",
    "Unique-Id":"44ff80ac-74d4-11ee-b962-0242ac120002",
    "certificate":"https://i.postimg.cc/dV7BW9kF/Jaideep-Popular-PG-Internship-Certificate-pdf.png",
  }, 
]

export default Data;