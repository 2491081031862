import React from "react";
import Ads from "../../../utils/Ads/Ads";
import Abou from "../../../assets/about.png";

const About = () => {
  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-column justify-content-center align-items-center mt-4 mx-4">
            <h1 className="mx-5 my-4">
              <span className="textorg fw-bold">Discover your </span>
              <span className="textsec fw-bold">dream home with us</span>
            </h1>
            <small className="mx-5">
              We are pleased to offer house listing services and provide various
              options and packages to assist you in finding your dream home.
            </small>
            <div className="container mx-5">
              <div className="row">
                <div className="col">
                  <h2 className="textorg">20k+</h2>
                  <p className="textsec">Happy customers with our service</p>
                </div>
                <div className="col">
                  <h2 className="textorg">225k+</h2>
                  <p className="textsec">The best properties we provide</p>
                </div>
                <div className="w-100"></div>
                <div className="col">
                  <h2 className="textorg">316k+</h2>
                  <p className="textsec">Companies affiliated with us</p>
                </div>
                <div className="col">
                  <h2 className="textorg">413k+</h2>
                  <p className="textsec">Projects completed</p>
                </div>
              </div>
            </div>
            
          </div><button className="btn Backorg my-4 mx-5 px-3 py-1">See more</button>
        </div>
        <div className="col-md-5">
          <img alt="img" src={Abou} className="img-fluid" />
        </div>
      </div>
<br/>
      <Ads />
    </div>
  );
};

export default About;
