import React, { useEffect, useState } from "react";
import ScheduleVist from "./page-components/scheduleVisit";
import Landing from "./page-components/landing";
import Detailscard from "./page-components/Detailscard";
import Services from "./page-components/Services";
import OtherServices from "./page-components/otherServices";
import Map from "./page-components/Map";
import axios from "axios";

const Pgdetails = () => {
  const [pg, setpg] = useState(null);

  useEffect(() => {
    const fetchdata = async () => {
      // get the id from the url
      const url = new URL(window.location.href);
      const idParam = url.pathname.split("/")[2];
      const response = await axios.get(`https://popularpg.in/products/${idParam}/`);
      console.log(response.data);
      setpg(response.data);
    };
    fetchdata();
  }, []);

  const servicesData = [];
  for (const key in pg?.furnishing_in_property) {
    if (pg?.furnishing_in_property[key]) {
      servicesData.push({ name: pg?.furnishing_in_property[key] });
    }
  }
  for (const key in pg?.security_amenities) {
    if (pg?.security_amenities[key]) {
      servicesData.push({ name: pg?.security_amenities[key] });
    }
  }
  for (const key in pg?.services_in_property) {
    if (pg?.services_in_property[key]) {
      servicesData.push({ name: pg?.services_in_property[key] });
    }
  }
  console.log(servicesData);

  return (
    <div className="pagemargin">
      {pg && <Landing data={pg} />}
      <div className="container-fluid">
        <div className="row mx-4">
          <div className="col-md-8 my-3">
            <div className="shadow rounded">
              {pg && <Detailscard data={pg} />}
            </div>
            <div className="my-2 rounded">
              <Services servicesData={servicesData} />
            </div>
            <div className="my-2 rounded">
              {pg && <Map data={pg.embedded_map_src_link} />}
            </div>
            <div className="my-2 rounded">
              {pg && <OtherServices locality={pg.locality} />}
            </div>
          </div>
          <div className="col-md-4">
            <ScheduleVist />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pgdetails;
