
import React from 'react';
import Searchbar from '../../../utils/SearchBarHome/Searchbar';
import Styles from "../page-style/Landing.module.css"
import Ads from "../../../utils/Ads/Ads"
const Landing = () => {
  return (
    <>
      <div className={`${Styles.landingcontainer}`}>
        <div className="d-flex align-center">
           <Searchbar /> 
        </div>
      </div> 
      <br/>
      <Ads  />
      <br/><br/>
    </>
  );
};

export default Landing;
