import React,{useContext} from "react";
import Logo from "../../assets/logowhite.png";
import { Link } from "react-router-dom";
import SearchContext from "../../ContextAPI/SearchContext";
const Footer = () => {
  const { setCityUrl } = useContext(
    SearchContext
  );
  return (
    <div>
      <div className="">
        <footer className="text-center text-white rounded">
          {/* first half footer  */}
          <div className="Backorg p-4 pb-0">
            <section className="">
              <div className="row d-flex justify-content-center">
                <div className="col-auto">
                  <p className="pt-2">
                    <strong>Sign up for our newsletter</strong>
                  </p>
                </div>

                <div className="col-md-5 col-12">
                  <div className="form-outline form-white mb-4">
                    <input
                      type="email"
                      id="form5Example2"
                      placeholder="Please, enter your email address."
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-auto">
                  <button
                    type="submit"
                    className="btn btn-outline-light mb-4 fw-semibold"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </section>
          </div>
          {/* middle of the footer  */}
          <div className="footermiddle ">
            <footer className=" text-center text-lg-start text-white">
              <div className="container p-4">
                <div className="row my-4">
                  {/* Company Section */}
                  <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <div
                      className="d-flex align-items-center justify-content-center mb-4 mx-auto"
                      style={{ width: "150px", height: "100px" }}
                    >
                      <img src={Logo} className="img-fluid" height="100" alt="" loading="lazy" />
                    </div>

                    <p className="text-center">
                      Popular PG is a marketplace for paying guest (PG)
                      accommodation in Bangalore. We connect PG seekers with PG
                      providers, making it easy to find the perfect PG for your
                      needs. Whether you are looking for a PG near your college
                      or workplace, or you are on a budget, we have the PG for
                      you.
                    </p>
                  </div>
                  {/* social media links  */}
                  

                  {/* Company Links */}
                  <div className="col-lg-3 col-md-6 mb-4 mb-md-0 my-4">
                    <h5 className="text-uppercase mb-4 textorg">Company</h5>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
                        <ul className="list-unstyled">
                          <li className="mb-2">
                            <Link
                              to="/" 
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              About Us
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/explore" 
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              Terms & Conditions
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/contact" 
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
                        <ul className="list-unstyled">
                          <li className="mb-2">
                            <Link
                              to="/explore" 
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              Privacy Policy
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/explore" 
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              Safety Guide
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* PG in Bangalore Section */}
                  <div className="col-lg-3 col-md-6 mb-4 mb-md-0 my-4">
                    <h5 className="text-uppercase mb-4 textorg">PG in Bangalore</h5>

                    <div className="row">
                      <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
                        <ul className="list-unstyled">
                          <li className="mb-2">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?locality=Marathahalli');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in Marathalli
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?locality=Madiwala');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              Pg in Madiwala
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?search=BTM+layout');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in BTM Layout
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?locality=Koramangala');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in Kormangala
                            </Link>
                          </li> 
                          <li className="mb-2">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?locality=Whitefield');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in Whitefield
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
                        <ul className="list-unstyled">
                         
                          <li className="mb-3">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?locality=HSR+Layout');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in HSR Layout
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?search=electronic%20city');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in Electronic City
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?locality=Bellandur');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in Bellandur
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/explore" onClick={()=>{
                                  setCityUrl('?locality=Sarjapur+Road');
                              }}
                              className="text-white"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              PG in Sarjapur Road
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>

          {/* End of the footer */}
          <div className="text-center p-3 footerbg">
            © 2023 Copyright:
            <a
              className="text-white"
              style={{
                textDecoration: "none",
              }}
              href="https://popularpg.com/"
            >
              PopularPG.com
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
