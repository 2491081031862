import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
const Contact = () => {
  return (
    <div className='contactmargin'>
      <section className="mb-4">
        <h1 className="fw-bold text-center my-4 textorg">Contact us</h1>

        <p className="text-center w-responsive mx-auto mb-5 rounded">
          Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within a
          matter of hours to help you.
        </p>

        <div className="row shadow m-4 py-4">
          <div className="col-md-9 mb-md-0 mb-5">
            <form id="contact-form" name="contact-form" action="/submit" method="POST">
              <div className="row">
                <div className="col-md-6">
                  <div className="md-form mb-0">
                    <label htmlFor="name">Your name</label>
                    <input type="text" id="name" name="name" className="form-control" />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="md-form mb-0">
                    <label htmlFor="email">Your email</label>
                    <input type="text" id="email" name="email" className="form-control" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="md-form mb-0">
                    <label htmlFor="subject">Subject</label>
                    <input type="text" id="subject" name="subject" className="form-control" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="md-form">
                    <label htmlFor="message">Your message</label>
                    <textarea type="text" id="message" name="message" rows="2" className="form-control md-textarea"></textarea>
                  </div>
                </div>
              </div>

              <div className=" text-md-left">
                <button className="btn Backorg m-2 px-4 py-1" type="submit">
                  Send
                </button>
              </div>
              <div className="status"></div>
            </form>
          </div>

          <div className="col-md-3 text-center">
            <ul className="list-unstyled mb-0">
              <li>
                <FaMapMarkerAlt className="fa-2x" />
                <p className='textorg'>San Francisco, CA 94126, USA</p>
              </li>

              <li>
                <FaPhone className="mt-4 fa-2x" />
                <p className='textorg'>+ 01 234 567 89</p>
              </li>

              <li>
                <FaEnvelope className="mt-4 fa-2x" />
                <p className='textorg'>contact@mdbootstrap.com</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
