import { Link } from "react-router-dom";
import { FaBed } from "react-icons/fa";
import "./Pgcard.css";
import React, { useState,useContext } from "react";
import Searchstate from "../../ContextAPI/SearchContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useOtp } from "../../../Context/OtpContext";
import OTPWidget from './../../pages/otp/OTPWidget';
import  axios  from 'axios';


const Pgcard = ({ pgData }) => {
  const { selectedPG, setSelectedPG } = useContext(Searchstate);
  const [contactData, setContactData] = useState({
    name: "",
    email: null,
    phone: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { isVerified } = useOtp();
  const formattedNumber = `91${contactData.phone}`;
  

  const handleSubmit = async () => {
    setSubmitted(true);
// Check if the phone number is verified
if (isVerified) {
  // Prepare the data to be sent
  const requestData = {
    customer_name: contactData.name || null,
    customer_email: contactData.email || null,
    customer_phone: formattedNumber,
    product: selectedPG ? selectedPG.id : null,
  };

  try {
    // Send the request using Axios
    const response = await axios.post("https://popularpg.in/booking/", requestData);

    // Handle the response as needed
    console.log(response.data);
    alert(`Booking successful! Contact owner at ${pgData.phone_number}`);
  } catch (error) {
    // Handle errors
    console.error("Error submitting form:", error);
  }
} else {
  // Handle the case when the phone number is not verified
  console.log("Phone number is not verified. Form not submitted.");
}
};

  const MAX_ADDRESS_LENGTH = 100; // Maximum length of the address to display
  let address = pgData.address;
  if (address.length > MAX_ADDRESS_LENGTH) {
    address = address.substring(0, MAX_ADDRESS_LENGTH) + "...";
  }

  return (
    <div className="container-fluid z-1">
      <div className="card mb-3 mx-auto container position-relative shadow ">
        <div className="row g-0">
          <div className="col-12 col-sm-6 col-md-4 d-flex justify-content-center ">
            <img
              className="img-fluid m-2 px-3 py-2 rounded"
              src={pgData.cover_image}
              alt="Property Images"
              style={{ height: "200px", width: "100%", objectFit: "cover" }}
            />
          </div>

          <div className="col-12 col-sm-6 col-md-8">
            <div className="row ">
              <div className="card-body">
                <div className="d-flex flex-wrap ">
                  <h6 className="name mr-2 text fw-bold listing-product-name">
                    {pgData.product_name}
                  </h6>
                  <h6 className="text-muted mx-2">{pgData.locality}</h6>
                </div>
                <div className="d-flex flex-wrap justify-content-between ">
                  <div className="d-flex flex-wrap">
                    <h5 className="card-title rate textorg mr-2">
                      Rs. {pgData.min_price}
                    </h5>
                    <h6>
                      <small className="text-muted mx-2">Onwards</small>
                    </h6>
                  </div>
                  <div style={{ marginTop: "-2.5rem" }}>
                    <h6>
                      {pgData.meals_available && (
                        <span className="badge Backorg mx-2 smallhide">
                          Food Included
                        </span>
                      )}
                    </h6>
                    <h6>
                      {pgData.total_beds > 2 && (
                        <span className="badge outlineorg mx-2 d-flex flex-wrap smallhide">
                          <ion-icon name="checkmark-outline"></ion-icon>Beds
                          available
                        </span>
                      )}
                    </h6>
                  </div>
                </div>

                <div className="d-lg-none ">
                  <div className="d-flex flex-wrap justify-content-center">
                    <hr className="line-divider smallhide" />
                  </div>
                </div>

                <div className="line3 sharing">
                  <span className="d-inline-block mx-1 fw-bold">
                    Available Beds :
                  </span>
                  <FaBed className="text-secondary mx-1" />
                  {pgData.single_sharing && (
                    <small className="d-inline-block"> Single </small>
                  )}
                  {pgData.double_sharing && (
                    <small className="d-inline-block">, Double </small>
                  )}
                  {pgData.triple_sharing && (
                    <small className="d-inline-block">,Triple</small>
                  )}
                </div>

                <div className="d-flex flex-wrap justify-content-start">
                  <p
                    className="listing-address text-muted d-flex smallhide  flex-wrap"
                    style={{
                      backgroundColor: "#D9D9D94D",
                      borderRadius: "5px",
                    }}
                  >
                    <FaMapMarkerAlt /> {address}
                  </p>
                </div>
              </div>
            </div>
            <div className="row my-2 ">
              <div className="d-flex  flex-wrap ">
                <Link
                  to={`/pgdetails/${pgData.id}`}
                  className="btn Backorg mx-2 "
                >
                  View more details
                </Link>
                <button
                  type="button"
                  className="btn outlineorg "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalCenter"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedPG(pgData);
                  }}
                >
                  View Phone No.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="d-flex flex-wrap justify-content-between mx-3 mt-2">
              <h5 className="modal-title ">Contact Owner</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <label
                  htmlFor="PGname"
                  className="fs-4 "
                  style={{ marginTop: "-1rem" }}
                >
                  <b>{selectedPG?selectedPG.product_name:null}</b>{" "}
                </label>
                <p
                  className="text-dark"
                  style={{
                    fontSize: "0.9rem",
                  }}
                >
                  {selectedPG&& selectedPG.locality}
                </p>
                <div className="form-group">
                  <label htmlFor="name" className="modal-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your name"
                    onChange={() => {
                      setContactData({
                        ...contactData,
                        name: document.getElementById("name").value,
                      });
                    }}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="phone" className="modal-label">
                    Phone Number
                  </label>
                  <input
                    type="tel" // Use type "tel" for phone numbers
                    className="form-control"
                    id="phone"
                    placeholder="Enter your phone number"
                    onChange={(e) => {
                      setContactData({
                        ...contactData,
                        phone: e.target.value,
                      });
                    }}
                  />
                </div>
                <p
                  className="text-dark"
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  { selectedPG && selectedPG.description}
                </p>
              </form>
              <button
                type="button"
                className="btn my-2 Backorg"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {submitted && !isVerified && <OTPWidget number={formattedNumber} />}
      
    </div>
  );
};

export default Pgcard;
