import React from "react";
import { FaStar } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
const Card = ({data}) => {
  return (
    <div>
      <div
        className="card shadow mx-2 position-relative cardsize" 
      >
      {data.total_beds>3 ?  <p className="Backorg text-uppercase rounded position-absolute top-0 end-0 py-1 px-2 mx-1 mt-2">
          beds available
        </p>:null}
        <img
            className="card-img-top imgsize img-fluid"
          src={data.cover_image}
          alt="Card imagecap"
          style={{ 
            height: "200px",
            width: "300px",
            objectFit: "cover",
          }}
        />

        <div className="card-body">
          <small className="textorg "> <FaLocationDot/> {data.locality}</small>
          <h6 className="textsec text-uppercase">{data.product_name}</h6>
          <div className="row">
            <div className="col-8">
              <p className="card-text textorg fw-bold">₹ {data.min_price} / Month</p>
            </div>
            <small className="col-4 textorg">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar /> 4
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
