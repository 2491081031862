import React, {  useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "../../../utils/Card/Card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import axios from "axios";
const PremiumListings = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidetoshow, setSlidetoshow] = useState(windowWidth < 600 ? 1 : 3);
  const [PremiumPGs, setPremiumPGs] = useState([]);

  useEffect(()=>{
    const fetchpremiumList = async()=>{
        const PremiumPGs = await axios.get("https://popularpg.in/premium_ads/");
        setPremiumPGs(PremiumPGs);
    };
    fetchpremiumList();
  },[]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSlidetoshow(windowWidth < 600 ? 1 : 3);
  }, [windowWidth]);

  const settings = {
    infinite: true,
    slidesToShow: slidetoshow,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="overflow-hidden">
        <div className="d-flex justify-content-center m-2 overflow-hidden">
            <h1> <span className="textorg">Premium</span> <span className="textsec">Listings</span>  </h1>
        </div>
        <br/>
        <div className=" align-items-center " style={{ 
        width: "80%", marginLeft: "10%"
      }} >
        <div className="">
          <Slider {...settings} >
          {PremiumPGs && PremiumPGs.data &&  PremiumPGs.data.results.map((data)=>{
            return data.is_active && (
               <div  key={data.id}>
              <div className="d-flex justify-content-center">
                <Card key={data.id} data={data} />
              </div>
            </div>
            )
          })}
          </Slider>
      </div>
      </div>   
        <br/><br/>     
        <div className="d-flex justify-content-center my-2">
            <Link  to="/explore" className="btn Backorg m-2 px-4 py-2 ">View All</Link>
        </div>
    </div>
  )
}

export default PremiumListings