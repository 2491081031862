import React from 'react';
import Styles from '../page-styles/Pgdetailslanding.module.css';
import Demo from '../../../assets/pgdetails.png';

const Landing = ({data}) => {
  console.log(data);
  return (
    <div className={`${Styles.landingcontainer}`}>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center">
          <div className={`col-md-4 img-fluid ${Styles.alignimage}`}>
            <img
              alt="Bootstrap ImagePreview"
              className={`img-fluid ${Styles.bootstrapImagePreview}`}
              src={Demo}
            />
          </div>
          <div className="col-md-8">
            <div className={`${Styles.margintop} ${Styles.transbg}`}>
              <div className="d-flex flex-wrap">
                  <h6>
                    <span className="badge Backorg mx-2 mt-3">Partner Verified</span>
                  </h6>
                {/* {data.is_brand_new && (
                  <h6>
                    <span className="badge bgwhitetextorg mx-2 mt-3">Brand New</span>
                  </h6>
                )} */}
              </div>
              <h4 className="textorg fw-semibold mx-2">₹{data.min_price} / onwards</h4>
              <h6 className="text-white mx-2">
                <b>Occupancy Type:</b> Single, Double, Triple
              </h6>
              <div className="d-flex flex-wrap">
                <h5>
                  <span className={`badge ${Styles.transbg1} mx-2 my-2`}>
                    {data.pg_for === 'girls' ? 'For Girls' : 'For Boys'}
                  </span>
                </h5>
                <h5>
                  <span className={`badge ${Styles.transbg1} mx-2 my-2`}>
                    {data.best_suited_for}
                  </span>
                </h5>
                {data.meals_available && (
                  <h5>
                    <span className={`badge ${Styles.transbg1} mx-2 my-2`}>
                      Meals Available
                    </span>
                  </h5>
                )}
                <h5>
                  <span className={`badge ${Styles.transbg1} mx-2 my-2`}>
                    Beds Available: {data.total_beds}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
