import React, { useContext, useEffect } from "react";
import SearchBar from "../../utils/SearchBarExplore/Searchbar";
import Styles from "./page-styles/ExploreLanding.module.css";
import Ads from "../../utils/Ads/Ads";
import Pgcard from "../../utils/Pgcard/Pgcard";
import SearchContext from "../../ContextAPI/SearchContext";
import Logo from "../../assets/logo.png";

const Explore = () => {
  const { currentPage, setCurrentPage, pgs, isLoading, setpgs } = useContext(
    SearchContext
  );
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Update pgs when pg data is changed from the SearchContext
    setpgs(pgs);
    setCurrentPage(currentPage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pgs, currentPage]);

  return (
    <>
      <div className={`${Styles.landingcontainer} pagemargin`}>
        <div className="d-flex align-center">
          <SearchBar />
        </div>
      </div>

      <br />
      <div className="">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="loading-container">
              <img src={Logo} alt="Loading..." className="spinner" />
            </div>
          </div>
        ) : pgs.length === 0 ? (
          <div className="d-flex justify-content-center">
            <div className="no-record-found fs-1">No record found.</div>
          </div>
        ) : (
          pgs && pgs.results &&
          pgs.results.map((pgData) => (
            <React.Fragment key={pgData.id}>
              <Pgcard pgData={pgData} />
              {(pgData.id + 1) % 5 === 0 && pgData.id + 1 !== pgs.length && (
                <div className="display-none-mobile">
                  <Ads />
                </div>
              )}
            </React.Fragment>
          ))
        )}
      </div>

      <div className="col-12 d-flex justify-content-center">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link textsec"
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  scrollToTop();
                }}
              >
                Previous
              </button>
            </li>
            <li
              className={`page-item `}
            >
              <button
                className="page-link textsec"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  scrollToTop();
                }}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Explore;
