import React from "react";

const ScheduleVisit = () => {
    return (
        <div>
            <form className="form-horizontal">
                <div className="bg-white rounded shadow  my-3">
                    <div className="form-group mx-4 my-3">
                        <br />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            id="name"
                        />
                    </div>
                    <div className="form-group mx-4 my-3">
                        <div className="input-group">
                            <div className="input-group-prepend" style={{}}>
                                <span className="input-group-text" id="basic-addon1">
                                    +91
                                </span>
                            </div>
                            <input
                                type="text"
                                placeholder="Mobile Number"
                                className="form-control"
                                id="mobile"
                            />
                        </div>
                        <h6>
                            <small className="badge bgwhitetextorg fw-lighter mx-2 my-3 text-wrap">
                                We are accepting bookings for stay duration of minimum 3 months.
                            </small>
                        </h6>
                    </div>
                </div>
                <div className="form-group mx-4 my-3">
                    <div className="form-check form-switch">
                        <label className="form-check-label" htmlFor="whatsappUpdates">
                            Get updates over WhatsApp
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                        />
                    </div>
                </div>
                <div className="form-group mx-4 my-3">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input "
                            id="termsAndConditions"
                        />
                        <label className="form-check-label" htmlFor="termsAndConditions">
                            I have read and agreed to the terms and conditions and privacy
                            policy and hereby confirm to proceed.
                        </label>
                    </div>
                </div>
                <div className="d-flex justify-content-center mx-4 my-3">
                    <button type="submit" className="btn Backorg text-center" id="myButton">
                        Schedule a visit 
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ScheduleVisit;
