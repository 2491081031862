// import React from "react";
import Card from "../../../utils/Card/CardForcity"
import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
const OtherServices = ({locality}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidetoshow, setSlidetoshow] = useState(windowWidth < 600 ? 1 : 2);
  const sliderRef = useRef(null);
  const [Pgs, setPgWhitefield] = useState([]);
  useEffect(()=>{
    const fetchpremiumList = async()=>{
      const url =  "https://popularpg.in/products/?search=" + locality;
        const Pgs = await axios.get(url);
        setPgWhitefield(Pgs.data);
    };
    fetchpremiumList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[locality]);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSlidetoshow(windowWidth < 600 ? 1 : 2);
  }, [windowWidth]);

  const settings = {
    infinite: true,
    slidesToShow: slidetoshow,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };


  return (
    <div className="mx-2 rounded">
      <h2 className=" fw-bold"> <span className="textorg">Other Similar </span> <span className="textsec">Properties</span></h2>
      <div className=" align-items-center ">
        <div className=" align-items-center " style={{ 
        width: "80%", marginLeft: "10%"
      }}>
          <Slider {...settings} ref={sliderRef}>
          {Pgs && Pgs.results &&  Pgs.results.map((data)=>{
            return (
               <div>
              <div className="d-flex justify-content-center">
                <Card key={data.id} data={data} />
              </div>
            </div>
            )
          })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OtherServices;
