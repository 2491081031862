import React from 'react'
import Landing from "./page-components/Landing";
import PremiumListings from "./page-components/PremiumListings";
import PopularListings from "./page-components/PopularListings";
import About from "./page-components/About";
import LisitingSliderComponent from './page-components/LisitingSliderComponent';
const Home = () => {
  return (
    <div className="pagemargin">
      <Landing />      
      <PremiumListings />
      <PopularListings />
      <About />
      <LisitingSliderComponent city="Electronic City" cityurl="?search=electronic%20city" bg="bgyellow" />
      <LisitingSliderComponent city="Whitefield" cityurl="?locality=Whitefield" bg="bg-white" />
      <LisitingSliderComponent city="Marathahalli" cityurl="?locality=Marathahalli" bg="bgyellow" />
      <LisitingSliderComponent city="Manyata Tech Park" cityurl="?locality=Manyata+Tech+Park" bg="bg-white" />
      <LisitingSliderComponent city="BTM Layout" cityurl="?search=BTM+layout" bg="bgyellow" />
      <LisitingSliderComponent city="Sarjapur Road" cityurl="?locality=Sarjapur+Road" bg="bg-white" />
      <LisitingSliderComponent city="Madiwala" cityurl="?locality=Madiwala" bg="bgyellow" />
      <LisitingSliderComponent city="Koramangala" cityurl="?locality=Koramangala" bg="bg-white" />
      <LisitingSliderComponent city="HSR Layout" cityurl="?locality=HSR+Layout" bg="bgyellow" />
      <LisitingSliderComponent city="Bellandur" cityurl="?locality=Bellandur" bg="bg-white" />
      <LisitingSliderComponent city="Lalbagh" cityurl="?locality=Lalbagh" bg="bgyellow" />
      <LisitingSliderComponent city="Kr Market" cityurl="?locality=Kr+Market" bg="bg-white" />
      <LisitingSliderComponent city="Jaynagar" cityurl="?locality=Jaynagar" bg="bgyellow" />
      <LisitingSliderComponent city="Banashankari" cityurl="?locality=Banashankari" bg="bg-white" />
      <LisitingSliderComponent city="JP Nagar" cityurl="?locality=JP+Nagar" bg="bgyellow" />
    </div>
  )
}

export default Home