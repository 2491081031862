import React from 'react'

const Booking = () => {
  return (
    <div className="container pagemargin">
      <h1 className="text-center fw-bold textorg">Booking Page</h1>
      <form>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" className="form-control" id="name" name="name" />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" className="form-control" id="email" name="email" />
        </div>

        <div className="form-group">
          <label htmlFor="date">Date:</label>
          <input type="date" className="form-control" id="date" name="date" />
        </div>

        <button type="submit" className="btn Backorg my-3">Book Now</button>
      </form>
    </div>
  )
}

export default Booking