import React from 'react';
import { FaWifi, FaShieldAlt } from 'react-icons/fa';
import { GiGasStove } from "react-icons/gi";
import { RiServiceLine } from "react-icons/ri";
import { BiSolidCctv } from "react-icons/bi";
import { CgGym } from "react-icons/cg";
import { MdCleaningServices } from "react-icons/md";
import { GiWashingMachine } from "react-icons/gi";
import { RiFridgeLine } from "react-icons/ri";
import { GiElevator } from "react-icons/gi";
import { PiTelevisionDuotone } from "react-icons/pi";
import Styles from '../page-styles/Services.module.css'
const Services = ({ servicesData }) => {
  const renderServiceIcon = (service) => {
    switch (service.toLowerCase()) {
      case 'wifi' || 'wi-fi':
        return <FaWifi  style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }}/>;
      case 'cleaning' || 'cleaning services' || 'cleaning-services' || 'cleaning_services' || 'cleaning service' || 'cleaning-service' || 'cleaning_service' || 'brooming':
        return <MdCleaningServices  style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }}/>;

      case 'tv' || 'television':
        return <PiTelevisionDuotone  style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }}/>;

      case 'lift' || 'elevator':
        return <GiElevator  style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }}/>;

      case 'fridge' || 'refrigerator' :
        return <RiFridgeLine  style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }}/>;

      case 'washing machine' || 'washing-machine' || 'washingmachine' || 'washing_machine':
        return <GiWashingMachine  style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }}/>;
      case 'authentication gate lock' || 'authentication-gate-lock' ||'lock' || 'gate lock' || 'gate-lock' || 'gate_lock' || 'gate-lock' || 'gate_lock' || 'gate':
        return <FaShieldAlt style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }} />;
        case 'electric stove' || 'electric-stove' || 'electric_stove' || 'gas stove' || 'gas-stove' || 'gas_stove' || 'gas-stove' || 'gas_stove' || 'gas' || 'stove' :
        return <GiGasStove style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }} />;
        case 'gym' || 'gymnasium' :
          return <CgGym style={{
            backgroundColor: "#FF5C0B1A",
            padding: "10px",
            borderRadius: "50%",
            margin: "10px",
            height: "50px",
            width: "50px",
          }} />;
        case 'cctv surveillance' || 'cctv-surveillance' || 'cctv_surveillance' || 'cctv' || 'surveillance':
          return <BiSolidCctv style={{
            backgroundColor: "#FF5C0B1A",
            padding: "10px",
            borderRadius: "50%",
            margin: "10px",
            height: "50px",
            width: "50px",
          }} />;
      default:
        return <RiServiceLine style={{
                  backgroundColor: "#FF5C0B1A",
                  padding: "10px",
                  borderRadius: "50%",
                  margin: "10px",
                  height: "50px",
                  width: "50px",
                }} />;
    }
  };

  return (
    <div className="mx-2 rounded">
      {servicesData.length > 0 && servicesData && <h2 className="textorg fw-bold">Services</h2>}
      <div className="d-flex flex-wrap">
        {servicesData.map((service, index) => (
          <div
            key={index}
            className="text-center "
          >
            <div className={`shadow rounded service-item ${Styles.Servicesmargin}`} style={{
              height: '9rem',
              width: '8rem',
            }}>
              <div className="service-icon bg-lightorange">
                {renderServiceIcon(service.name)}
              </div>
              <p className="text-muted my-2">{service.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
