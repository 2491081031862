import React, { useContext } from "react";
import Select from "react-select";
import { FaMapMarkerAlt, FaSearch, FaDollarSign, FaSort } from "react-icons/fa";
import Styles from "./Searchbar.module.css";
import { BsGenderMale } from "react-icons/bs";
import { MdShare } from "react-icons/md";
import SearchContext from "../../ContextAPI/SearchContext";

const SearchBar = () => {
  const {
    data,
    setData,
    setMinValue,
    setMaxValue,
    location,
    setLocation,
    gender,
    setGender,
    occupancytype,
    setOccupancyType,
    sortType,
    setSortType,
    handleSearch,
  } = useContext(SearchContext);
  const locationOptions = [
    { value: "Marathahalli", label: "Marathahalli" },
    { value: "BTM Layout", label: "BTM Layout" },
    { value: "BTM Layout Stage 1", label: "BTM Layout Stage 1" },
    { value: "BTM Layout Stage 2", label: "BTM Layout Stage 2" },
    { value: "Electronic City", label: "Electronic City" },
    { value: "Electronic City Phase 1", label: "Electronic City Phase 1" },
    { value: "Electronic City Phase 2", label: "Electronic City Phase 2" },
    { value: "Manyata Tech Park", label: "Manyata Tech Park" },
    { value: "Whitefield", label: "Whitefield" },
    { value: "Sarjapur Road", label: "Sarjapur Road" },
    { value: "Madiwala", label: "Madiwala" },
    { value: "Koramangala", label: "Koramangala" },
    { value: "HSR Layout", label: "HSR Layout" },
    { value: "Bellandur", label: "Bellandur" },
    { value: "Lalbagh", label: "Lalbagh" },
    { value: "Kr Market", label: "Kr Market" },
    { value: "Jaynagar", label: "Jaynagar" },
    { value: "Banashankari", label: "Banashankari" },
    { value: "JP Nagar", label: "JP Nagar" },
  ];

  return (
    <div
      className={`container d-flex justify-content-center ${Styles.aligncenter} ${Styles.exploresearchbar} `}
    >
      <div className="d-flex justify-content-center flex-wrap">
        <div className="">
          <form
            className="bg-white p-3 rounded "
            style={{
              border: "0.8rem solid #061E30",
            }}
          >
            <div className="d-flex justify-content-center flex-wrap flexdircloumn">
              <div className="">
                <div className="input-group m-1">
                  <span className="input-group-text textorg">
                    <FaMapMarkerAlt />
                  </span>
                  <Select
                    className="location_input_select"
                    isMulti
                    value={locationOptions.filter((option) =>
                      location.includes(option.value)
                    )}
                    options={locationOptions}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map(
                        (option) => option.value
                      );
                      setLocation(selectedValues);
                    }}
                    placeholder="Location"
                    styles={{
                      control: (base) => ({
                        ...base,
                        maxHeight: 200,
                        minWidth: 190,
                        overflowX: "auto",
                      }),
                      menuList: (base) => ({
                        ...base,
                        maxHeight: 200,
                        minWidth: 190,
                        overflowX: "auto",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div className="input-group m-1">
                  <span className="input-group-text textorg">
                    <MdShare />
                  </span>
                  <Select
                    className="location_input_select"
                    value={occupancytype}
                    options={[
                      { value: "single", label: "Single" },
                      { value: "double", label: "Double" },
                      { value: "triple", label: "Triple" },
                    ]}
                    onChange={(selectedOption) =>
                      setOccupancyType(selectedOption)
                    }
                    placeholder="Sharing Type"
                    styles={{
                      control: (base) => ({
                        ...base,
                        minWidth: 190,
                        width: "fit-content",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div className="input-group m-1">
                  <span className="input-group-text textorg">
                    <BsGenderMale />
                  </span>
                  <Select
                    className="location_input_select"
                    value={ gender }
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                    ]}
                    onChange={(selectedOption) =>
                      setGender(selectedOption.value)
                    }
                    placeholder="Select Gender"
                    styles={{
                      control: (base) => ({
                        ...base,
                        minWidth: 190,
                        width: "fit-content",
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-wrap flexdircloumn">
              <div className="">
                <div className="input-group m-1">
                  <span className="input-group-text textorg">
                    <FaDollarSign />
                  </span>
                  <Select
                    className="location_input_select"
                    value={{ value: data, label: data }}
                    options={[
                      { value: "6000", label: "6000" },
                      { value: "7000", label: "7000" },
                      { value: "8000", label: "8000" },
                      { value: "9000", label: "9000" },
                      { value: "10000", label: "10000" },
                      { value: "11000", label: "11000" },
                      { value: "12000", label: "12000" },
                      { value: "13000", label: "13000" },
                      { value: "14000", label: "14000" },
                    ]}
                    onChange={(selectedOption) => {
                      setData(selectedOption.value);
                      setMinValue("");
                      setMaxValue("");
                    }}
                    placeholder="Budget"
                    styles={{
                      control: (base) => ({
                        ...base,
                        minWidth: 190,
                        width: "fit-content",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="">
                <div className="input-group m-1">
                  <span className="input-group-text textorg">
                    <FaSort />
                  </span>
                  <Select
                    className="location_input_select"
                    value={sortType}
                    options={[
                      { value: "asc", label: "Ascending" },
                      { value: "desc", label: "Descending" },
                    ]}
                    onChange={(selectedOption) =>
                      setSortType(selectedOption.value)
                    }
                    placeholder="Sort By"
                    styles={{
                      control: (base) => ({
                        ...base,
                        minWidth: 190,
                        width: "fit-content",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className=""
              style={{ 
                width: "fit-content",
                minWidth: "190px",
                margin: "0 auto"
              }}
              >
                <button
                  type="button"
                  className="btn Backorg  w-100"
                  onClick={handleSearch}
                >
                  <FaSearch /> Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
