import React from 'react';
import Styles from "./Navbar.module.css";
import Logo from "../../assets/logo.png";
import { FaHome} from 'react-icons/fa';
import { FaBed } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import { IoStorefront } from "react-icons/io5";
import { RiUserShared2Fill } from "react-icons/ri";
import { Link ,useLocation} from "react-router-dom";
const SmallscreenNavbar = () => {
    const links = [
        { icon: <FaHome />, name: 'Home',to:"/" },
        { icon: <FaBed />, name: 'Explore Pg',to:"/explore" },
        { icon: <MdContacts />, name: 'Contact',to:"/contact" },
        { icon: <IoStorefront />, name: 'My store',to:"/store" },
        { icon: <RiUserShared2Fill />, name: 'Sign in',to:"/register" },

    ];
    const location = useLocation();

    return (
        <>
        <div className={`header-top fixed-top d-flex justify-content-between align-items-center py-2`} style={{ backgroundColor: '#fff' }}>
            <div className="d-flex align-items-center">
                <Link to="/" className={`navbar-brand ${Styles.logoContainer}`}>
                    <img src={Logo} alt="Logo" height="45" width="100" className={`d-inline-block align-top img-fluid ${Styles.logoimg}`} />
                </Link>
            </div>
            <div className="d-flex">
             <Link to="/login" className="btn Backorg mx-4 px-3">Login</Link>
           </div>
            
        </div>

        <div className={`header-bottom ${Styles.onsmallscreen}  d-flex justify-content-around py-3`} style={{ backgroundColor: '#000045', position: 'fixed', bottom: 0, width: '100%' }}>
            {links.map((link, index) => (
                <div key={index} className="text-white">
                    <Link className={`nav-link d-flex flex-column  ${location.pathname === `${link.to}` ? Styles.active : ''}`} to={link.to}>
                        <div className=' d-flex justify-content-center '>{link.icon}</div>
                        <small>{link.name}</small>
                    </Link>
                </div>
            ))}
        </div>
        </>
    );
}

export default SmallscreenNavbar;
