import React, { useState } from "react";
import { Link } from "react-router-dom";
import Login from "../../assets/login1.png";
import Logo from "../../assets/logo.png";

const LoginForm = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");

  const handleVerify = () => {
    if (mobileNumber.trim() !== "") {
      // setIsRegistered(true);
    }
  };

  return (
    <div className="container my-5 pagemargin">
      <div className="row d-flex justify-content-center">
        <div className="col-md-6 d-flex justify-content-center">
          <img src={Login} alt="Login" className="img-fluid loginimg" />
        </div>

        <div className="col-md-6">
          <div className="rounded shadow p-4">
            <div className="text-center ">
              <img src={Logo} alt="Logo" className="img-fluid" width="200" />
            </div>
            <p className="text-muted text-center">Welcome Back!!</p>
            <h2 className="fw-bold text-center">Sign in</h2>
            {/* Mobile Number Input */}
            <div className="mb-3">
              <label htmlFor="mobileNumber" className="form-label">
                Mobile Number
              </label>
              <input
                type="tel"
                className="form-control"
                id="mobileNumber"
                placeholder="Enter your mobile number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>

            {/* OTP Input */}
            <div className="mb-3">
              <label htmlFor="otp" className="form-label">
                OTP
              </label>
              <input
                type="text"
                className="form-control"
                id="otp"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>

            {/* Verify Button */}
            <div className="text-center">
              <button className="btn Backorg" onClick={handleVerify}>
                Verify
              </button>
            </div>

            {/* Redirect to Sign In */}
            <p className="mt-3 text-center text-muted">
              Not registered? 
              <Link to="/register" className="textorg">
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
