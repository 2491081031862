import React from 'react'
import Adspic from "../../assets/ads.png"
const Ads = () => {
  return (
    <div className="d-flex justify-content-center my-3">
        <img src={Adspic} alt="Adspic" className="img-fluid mx-4 border-rounded" /> 
    </div>
  )
}

export default Ads;